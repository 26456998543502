<!-- @format -->

<template>
  <!-- PRESS-MODAL -->
  <div
    v-if="pressmodal"
    class="absolute lg:fixed top-0 left-0 w-full bg-transparent lg:bg-modal z-modal lg:h-screen flex justify-center items-center shadow-lg"
  >
    <div
      class="flex flex-col lg:flex-row relative border-secondary border-4 w-full lg:w-1/3"
    >
      <div class="hidden lg:block">
        <!-- <img src="~/assets/images/presse_plakat.jpg" alt /> -->
      </div>
      <div class="relative bg-white flex-1 p-4 lg:p-10 text-left">
        <h1 class="font-bold mb-4 text-2xl lg:text-3xl leading-none">
          Betreuende Presseagentur:
        </h1>
        <address class="text-xl lg:text-2xl">
          <div class="font-bold">
            {{ $config.agencies[$config.press['agency']]['name'] }}
          </div>
          <div class="mt-4">
            {{ $config.agencies[$config.press['agency']]['street'] }} <br />
            {{ $config.agencies[$config.press['agency']]['city'] }} <br />
            {{ $config.agencies[$config.press['agency']]['phone'] }} <br />
            {{ $config.agencies[$config.press['agency']]['fax'] }} <br />
            <a
              :href="
                'mailto:' + $config.agencies[$config.press['agency']]['email']
              "
              >{{ $config.agencies[$config.press['agency']]['email'] }}</a
            >
          </div>
        </address>
        <p class="mt-12 text-xl">
          <a href="//www.filmpresskit.de/" target="_blank"
            >Zum Presseserver >></a
          >
        </p>
        <div
          class="absolute top-0 right-0 hover:text-red-500 text-black mr-4 mt-2 cursor-pointer text-2xl font-bold"
          @click="close"
        >
          <font-awesome-icon :icon="['fas', 'times']" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { EventBus } from '@/plugins/event-bus.js'
  export default {
    data() {
      return {
        pressmodal: false,
      }
    },
    created() {
      /* eslint-disable-next-line no-undef */
      EventBus.$on(
        'pressmodal',
        function () {
          this.pressmodal = true
        }.bind(this)
      )
    },
    methods: {
      close() {
        this.pressmodal = false
      },
    },
  }
</script>

<style lang="scss"></style>
